@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Museo";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("/font/2C4B03_8_0.eot");
  src: url("/font/2C4B03_8_0.eot?#iefix") format("embedded-opentype"),
    url("/font/2C4B03_8_0.woff2") format("woff2"),
    url("/font/2C4B03_8_0.woff") format("woff"),
    url("/font/2C4B03_8_0.ttf") format("truetype");
}

@font-face {
  font-family: "Museo";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/font/2C4B03_5_0.eot");
  src: url("/font/2C4B03_5_0.eot?#iefix") format("embedded-opentype"),
    url("/font/2C4B03_5_0.woff2") format("woff2"),
    url("/font/2C4B03_5_0.woff") format("woff"),
    url("/font/2C4B03_5_0.ttf") format("truetype");
}

@font-face {
  font-family: "Museo";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("/font/2C4B03_6_0.eot");
  src: url("/font/2C4B03_6_0.eot?#iefix") format("embedded-opentype"),
    url("/font/2C4B03_6_0.woff2") format("woff2"),
    url("/font/2C4B03_6_0.woff") format("woff"),
    url("/font/2C4B03_6_0.ttf") format("truetype");
}

.block-content a {
  color: #79bc43;
}

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 224 71.4% 4.1%;

    --card: 0 0% 100%;
    --card-foreground: 224 71.4% 4.1%;

    --popover: 0 0% 100%;
    --popover-foreground: 224 71.4% 4.1%;

    --primary: 220.9 39.3% 11%;
    --primary-foreground: 210 20% 98%;

    --secondary: 220 14.3% 95.9%;
    --secondary-foreground: 220.9 39.3% 11%;

    --muted: 220 14.3% 95.9%;
    --muted-foreground: 220 8.9% 46.1%;

    --accent: 93.2 47.5% 50%;
    --accent-foreground: 220.9 39.3% 11%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 20% 98%;

    --border: 220 13% 91%;
    --input: 220 13% 91%;
    --ring: 224 71.4% 4.1%;

    --radius: 0.5rem;
  }

  .dark {
    /* --background: 224 71.4% 4.1%;
    --foreground: 210 20% 98%;
 
    --card: 224 71.4% 4.1%;
    --card-foreground: 210 20% 98%;
 
    --popover: 224 71.4% 4.1%;
    --popover-foreground: 210 20% 98%;
 
    --primary: 210 20% 98%;
    --primary-foreground: 220.9 39.3% 11%;
 
    --secondary: 215 27.9% 16.9%;
    --secondary-foreground: 210 20% 98%;
 
    --muted: 215 27.9% 16.9%;
    --muted-foreground: 217.9 10.6% 64.9%;
 
    --accent: 215 27.9% 16.9%;
    --accent-foreground: 210 20% 98%;
 
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 210 20% 98%;
 
    --border: 215 27.9% 16.9%;
    --input: 215 27.9% 16.9%;
    --ring: 216 12.2% 83.9%; */

    --background: 240 10% 3.9%;
    --foreground: 0 0% 98%;
    --card: 240 10% 3.9%;
    --card-foreground: 0 0% 98%;
    --popover: 240 10% 3.9%;
    --popover-foreground: 0 0% 98%;
    --primary: 0 0% 98%;
    --primary-foreground: 240 5.9% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 240 3.7% 15.9%;
    --muted-foreground: 240 5% 64.9%;

    --accent: 240 3.7% 15.9%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 240 4.9% 83.9%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Scroll snapping */
.snap-x {
  scroll-snap-type: x mandatory;
}

.snap-start {
  scroll-snap-align: start;
}
